.banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    
  }
  
  .title {
    position: absolute;
    bottom: 16%;
    left: 0;
    width: 100%;
    padding: 1rem;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    z-index: 1001;
    line-height:.8;
  }
  .childComponent
  {
    /* place under the title */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    z-index: 1001;
    border:thin solid white;
  }
  
  .gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(to top,#343838, transparent);
    z-index: 1000;
  }
  