html, body{}
.background-dark-blue {
  background-color: #343838; 
}
.color-dark
{
  color: #343838;
}
.color-med {
  color:#008C9E
}

.color-light {
color: "#005F6B";
 border: "none";
}
.color-dark-green
{
  color:#173740;
}
.color-med-green
{
  color:rgb(0, 95, 107);
}
.color-light-green
{
  color:#417A69;
}
.backgroundGreen
{
  background: "rgb(129, 196, 163)",
}
.App {
  text-align: center;
  position: relative;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.page {
  min-height: 1024;
  padding-bottom: 100px;
}
.full-width-modal-body {
  width: 100%;
}
.border-select {
  border: 2px solid #008C9E;
  border-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.paragraph
{
  width:80%;
  margin-left:auto;
  margin-right:auto;
  font-size: 1.2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* for the server upgrade notices */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
}
