@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .fade-out{
    animation: fade-out 0.8s ease-in;
    opacity: 0;
  }
  .fade-in {
    animation: fade-in 0.8s ease-in;
    opacity: 1;
  }
  

  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slide-out {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .slide-in {
    animation: slide-in 0.5s ease-in;
    transform: translateX(-100%);
    
  }
  
  .slide-out {
    animation: slide-out 0.5s ease-out;
    transform: translateX(0);
  }
  